import React, { useState } from "react"
import { Link } from "gatsby"
import scrollTo from "gatsby-plugin-smoothscroll"

const Header = ({ useLinks }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <header className="header" id="header">
      <div className="container">
        <div className="inner-container">
          <div className="logo">
            <Link to="/">Tx</Link>
          </div>

          <div className={isOpen ? "navigation active" : "navigation"}>
            {useLinks ? (
              <nav>
                <Link to="/#welcome">About</Link>
                <Link to="/#ideology">Ideology</Link>
                <Link to="/#services">Services</Link>
                <Link to="/#insights">Insights</Link>
                <Link to="/#footer">Contact</Link>
              </nav>
            ) : (
              <nav>
                <button onClick={() => scrollTo("#welcome")}>About</button>
                <button onClick={() => scrollTo("#ideology")}>Ideology</button>
                <button onClick={() => scrollTo("#services")}>Services</button>
                <button onClick={() => scrollTo("#insights")}>Insights</button>
                <button onClick={() => scrollTo("#footer")}>Contact</button>
              </nav>
            )}
          </div>
          <div
            className={isOpen ? "menu-btn active" : "menu-btn not-active"}
            onClick={() => setIsOpen(!isOpen)}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </header>
  )
}
export default Header
