import React from "react"
import IconFacebook from "../images/svg/IconFacebook.svg"
import IconLinkedIn from "../images/svg/IconLinkedIn.svg"
import IconMail from "../images/svg/IconMail.svg"
import scrollTo from "gatsby-plugin-smoothscroll"
import { Link } from "gatsby"

const Footer = ({ useLinks, location }) => {
  return (
    <footer className="footer" id="footer">
      <div className="container">
        <div className="content">
          {location && location.pathname === "/" && (
            <div className="joinus">
              <a href="/careers">Join our Team</a>
            </div>
          )}
          <ul className="social">
            {/* <li>
              <a
                href="https://facebook.com"
                target="_blank"
                rel="noreferrer noopener"
              >
                <IconFacebook />
              </a>
            </li> */}
            <li>
              <a
                href="https://www.linkedin.com/company/techscoped"
                target="_blank"
                rel="noreferrer"
              >
                <IconLinkedIn />
              </a>
            </li>
            <li>
              <a href="mailto:hello@techscoped.com">
                <IconMail />
              </a>
            </li>
          </ul>
          {useLinks ? (
            <ul className="footer-menu">
              <li>
                <Link to="/#header">Home</Link>
              </li>
              <li>
                <Link to="/#welcome">About</Link>
              </li>
              <li>
                <Link to="/#ideology">Ideology</Link>
              </li>
              <li>
                <Link to="/#services">Services</Link>
              </li>
              <li>
                <Link to="/#insights">Insights</Link>
              </li>
            </ul>
          ) : (
            <ul className="footer-menu">
              <li>
                <button onClick={() => scrollTo("#header")}>Home</button>
              </li>
              <li>
                <button onClick={() => scrollTo("#welcome")}>About</button>
              </li>
              <li>
                <button onClick={() => scrollTo("#ideology")}>Ideology</button>
              </li>
              <li>
                <button onClick={() => scrollTo("#services")}>Services</button>
              </li>
              <li>
                <button onClick={() => scrollTo("#insights")}>Insights</button>
              </li>
            </ul>
          )}
          <p className="copyright">
            © 2024 TECHSCOPED.COM. ALL RIGHTS RESERVED | &nbsp;
            <a href="/privacy-policy">Privacy Policy</a>
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
