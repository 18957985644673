import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import { Link } from "gatsby"
import InsightWide from "../images/insights/Insight-1-wide.jpg"

const Insight = () => (
  <div>
    <Header />
    <section className="insight-post">
      <h1>TITLE HEADING</h1>
      <h4>Title description, Dec 7, 2017</h4>

      <div
        className="featured-img"
        style={{ backgroundImage: "url(" + InsightWide + ")" }}
      ></div>

      <div className="container">
        <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
        <p>
          Sunt in culpa qui officia deserunt mollit anim id est laborum
          consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
          labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
          exercitation ullamco.
        </p>
        <p>
          Sunt in culpa qui officia deserunt mollit anim id est laborum
          consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
          labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
          exercitation ullamco.
        </p>
        <p>
          Sunt in culpa qui officia deserunt mollit anim id est laborum
          consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
          labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
          exercitation ullamco.
        </p>
        <p>
          Sunt in culpa qui officia deserunt mollit anim id est laborum
          consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
          labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
          exercitation ullamco.
        </p>
        <Link to="/">&#8592; Back</Link>
      </div>
    </section>
    <Footer />
  </div>
)

export default Insight
